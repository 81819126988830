// export const rafAsync = (request: any) => {
//   return new Promise((resolve) => {
//     request = requestAnimationFrame(resolve);
//     return requestAnimationFrame(resolve);
//   });
// };

// export const checkElement = (selector: string) =>
//   document.querySelector(selector) === null ?
//     rafAsync().then(() => checkElement(selector)) :
//     Promise.resolve(true);

// export const checkElement = (document: Document, selector: string) => {
//   const request = null;
//   if (
//     document.querySelector(selector) === null ||
//     document.querySelector(selector) === undefined
//   ) {
//     return rafAsync(request).then(() => checkElement(document, selector));
//   } else {
//     cancelAnimationFrame(request);
//     return Promise.resolve(true);
//   }
// };

// export const checkLength = (document: Document, selector: string) => {
//   const request = null;
//   if (
//     document.querySelectorAll(selector) === null ||
//     document.querySelectorAll(selector) === undefined ||
//     Array.from(document.querySelectorAll(selector)).length <= 0
//   ) {
//     return rafAsync(request).then(() => checkLength(document, selector));
//   } else {
//     // console.log(Array.from(document.querySelectorAll(selector)).length);
//     cancelAnimationFrame(request);
//     return Promise.resolve(true);
//   }
// };

// export const expectLength = (
//   document: Document,
//   selector: string,
//   length: number,
// ) => {
//   const request = null;
//   if (
//     document.querySelectorAll(selector) === null ||
//     document.querySelectorAll(selector) === undefined ||
//     Array.from(document.querySelectorAll(selector)).length < length
//   ) {
//     return rafAsync(request).then(() => checkLength(document, selector));
//   } else {
//     // console.log(Array.from(document.querySelectorAll(selector)).length);
//     cancelAnimationFrame(request);
//     return Promise.resolve(true);
//   }
// };

// export const checkFunction = (fn: Function) => {
//   // console.log(fn);
//   const request = null;
//   if (fn === null || fn === undefined) {
//     return rafAsync(request).then(() => checkFunction(fn));
//   } else {
//     cancelAnimationFrame(request);
//     return Promise.resolve(true);
//   }
// };

// export const checkValue = (selector: string) => {
//   // console.log(value === 0);
//   const value = document.querySelector(selector).getBoundingClientRect().height;

//   const request = null;
//   if (value === 0) {
//     return rafAsync(request).then(() => checkValue(selector));
//   } else {
//     cancelAnimationFrame(request);
//     return Promise.resolve(true);
//   }
// };

export const rand = () => {
  return Math.random().toString(36).substr(2);
};

export const token = () => {
  return rand() + rand();
};

export const isEmpty = (obj: any) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

/**
 * Fancy ID generator that creates 20-character string identifiers with the following properties:
 *
 * 1. They're based on timestamp so that they sort *after* any existing ids.
 * 2. They contain 72-bits of random data after the timestamp so that IDs won't collide with other clients' IDs.
 * 3. They sort *lexicographically* (so the timestamp is converted to characters that will sort properly).
 * 4. They're monotonically increasing.  Even if you generate more than one in the same timestamp, the
 *    latter ones will sort after the former ones.  We do this by using the previous random bits
 *    but "incrementing" them by 1 (only in the case of a timestamp collision).
 */
// const generatePushID = (function () {
//   // Modeled after base64 web-safe chars, but ordered by ASCII.
//   const PUSH_CHARS =
//     '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

//   // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
//   let lastPushTime = 0;

//   // We generate 72-bits of randomness which get turned into 12 characters and appended to the
//   // timestamp to prevent collisions with other clients.  We store the last characters we
//   // generated because in the event of a collision, we'll use those same characters except
//   // "incremented" by one.
//   const lastRandChars = [];

//   return function () {
//     let now = new Date().getTime();
//     const duplicateTime = now === lastPushTime;
//     lastPushTime = now;

//     const timeStampChars = new Array(8);
//     for (let i = 7; i >= 0; i--) {
//       timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
//       // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
//       now = Math.floor(now / 64);
//     }
//     if (now !== 0)
//       throw new Error('We should have converted the entire timestamp.');

//     let id = timeStampChars.join('');

//     if (!duplicateTime) {
//       for (let i = 0; i < 12; i++) {
//         lastRandChars[i] = Math.floor(Math.random() * 64);
//       }
//     } else {
//       // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
//       for (let i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
//         lastRandChars[i] = 0;
//       }
//       lastRandChars[i]++;
//     }
//     for (let i = 0; i < 12; i++) {
//       id += PUSH_CHARS.charAt(lastRandChars[i]);
//     }
//     if (id.length != 20) throw new Error('Length should be 20.');

//     return id;
//   };
// })();

// export { generatePushID };

export const toBase64 = (file): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const randomString = (length: number): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const utf8_to_b64 = (value: string): string => {
  return window.btoa(unescape(encodeURIComponent(value)));
};

export const b64_to_utf8 = (value: string): string => {
  return decodeURIComponent(escape(window.atob(value)));
};

export const formatDateWithZone = (): string => {
  // return (
  //   new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString() +
  //   '+02:00'
  // );
  const x =
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .replace('Z', '') + '+02:00';

  return x;
};

export const getFormattedDate = (): string => {
  // return (
  //   new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString() +
  //   '+02:00'
  // );
  const x =
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .replace('Z', '') + '+02:00';

  const x1 = new Date(x);

  const x2 = ('0' + x1.getDate()).slice(-2);
  const x3 = ('0' + (x1.getMonth() + 1)).slice(-2);
  const x4 = x1.getFullYear();

  return `${x2}.${x3}.${x4}`;
};

export const getFormattedTime = (): string => {
  // return (
  //   new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString() +
  //   '+02:00'
  // );
  const x =
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .replace('Z', '') + '+02:00';

  const x1 = new Date(x);

  const x2 = ('0' + x1.getHours()).slice(-2);
  const x3 = ('0' + x1.getMinutes()).slice(-2);

  return `${x2}:${x3}`;
};
// export const formatDateWithZone = (date: Date): string => {
//   const s = date.toLocaleString('de-DE', {
//     timeZone: 'Europe/Berlin',
//     timeZoneName: 'longOffset',
//     formatMatcher: 'basic',
//     year: 'numeric',
//     day: '2-digit',
//     month: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: 'numeric',
//   });

//   // console.log(s);
//   const a = s.split(/\D/);
//   // console.log(a);
//   return (
//     a[2] +
//     '-' +
//     a[1] +
//     '-' +
//     a[0] +
//     'T' +
//     a[4] +
//     ':' +
//     a[5] +
//     ':' +
//     a[6] +
//     '+' +
//     a[11] +
//     ':' +
//     a[12]
//   );
// };
