import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-bottom-navigation-bar',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, NgOptimizedImage],
  templateUrl: './bottom-navigation-bar.component.html',
  styleUrls: ['./bottom-navigation-bar.component.scss'],
})
export class BottomNavigationBarComponent {
  public opened = false;

  placeOrderDialogActive = this.pageService.placeOrderDialogActive;
  mobileNavigationActive = this.pageService.mobileNavigationActive;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private pageService: PageService,
    private router: Router,
  ) {}

  public toggleMenu(): void {
    this.pageService.toggleMobileNavigationActive();
  }

  public openDialog(): void {
    const scrollTop = this.document.querySelector('html').scrollTop;
    this.pageService.setPreviousScrollPosition(scrollTop);
    this.pageService.setPlaceOrderDialogActive(true);
    this.router.navigate(['', { outlets: { auftragOutlet: 'auftrag' } }], {
      skipLocationChange: true,
    });
  }

  // public openContactOptionsPage(): void {
  //   console.log('openContactOptionsPage');
  //   const scrollTop = this.document.querySelector('html').scrollTop;
  //   this.pageService.setPreviousScrollPosition(scrollTop);

  //   this.router.navigate(
  //     ['', { outlets: { contactOptionsPageOutlet: 'contact' } }],
  //     {
  //       skipLocationChange: false,
  //     },
  //   );
  // }
}
