import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private scrollbarWidth = new BehaviorSubject<number>(0);
  private previousScrollPosition = new BehaviorSubject<number>(0);

  public mobileNavigationActive = signal(false);

  public placeOrderDialogActive = signal(false);

  constructor() {}

  get scrollbarWidth$(): Observable<number> {
    return this.scrollbarWidth.asObservable();
  }

  setScrollbarWidth(value: number): void {
    this.scrollbarWidth.next(value);
  }

  get previousScrollPosition$(): Observable<number> {
    return this.previousScrollPosition.asObservable();
  }

  setPreviousScrollPosition(value: number): void {
    this.previousScrollPosition.next(value);
  }

  toggleMobileNavigationActive(): void {
    this.mobileNavigationActive.update((value) => !value);
  }

  setMobileNavigationActive(value: boolean): void {
    this.mobileNavigationActive.set(value);
  }

  setPlaceOrderDialogActive(value: boolean): void {
    this.placeOrderDialogActive.set(value);
  }
}
