import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarComponent } from './components/bar/bar.component';
import { TrailingSlashUrlSerializer } from './trailing-slash-url-serializer';
import { BottomNavigationBarComponent } from './components/bottom-navigation-bar/bottom-navigation-bar.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserModule.withServerTransition({
      appId: 'serverApp',
    }),
    BrowserAnimationsModule,
    BarComponent,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatSnackBarModule,
    MatProgressBarModule,
    NgOptimizedImage,
    IntersectionObserverModule,
    BottomNavigationBarComponent,

    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireStorageModule,
    // AngularFireDatabaseModule,
    // AngularFirestoreModule,
    // AngularFireAuthModule,
    // AngularFireAuthGuardModule,
    // AngularFireRemoteConfigModule,
    // AngularFireMessagingModule,
    // AngularFireFunctionsModule,
    // AngularFirePerformanceModule,

    // provideFirebaseApp(() => initializeApp(environment.firebase)),

    // provideAuth(() => {
    //   const auth = getAuth();
    //   if (environment.useEmulators) {
    //     connectAuthEmulator(auth, 'http://localhost:9092', {
    //       disableWarnings: true,
    //     });
    //   }
    //   return auth;
    // }),
    // provideDatabase(() => {
    //   const database = getDatabase();
    //   if (environment.useEmulators) {
    //     connectDatabaseEmulator(database, 'localhost', 9082);
    //   }
    //   return database;
    // }),

    // provideFirestore(() => {
    //   const firestore = getFirestore();
    //   // if (environment.useEmulators) {
    //   //   connectFirestoreEmulator(firestore, 'localhost', 8082);
    //   // }
    //   // enableMultiTabIndexedDbPersistence(firestore).then(
    //   //   () => resolvePersistenceEnabled(true),
    //   //   () => resolvePersistenceEnabled(false),
    //   // );
    //   return firestore;
    // }),

    // provideFunctions(() => {
    //   const functions = getFunctions();
    //   if (environment.useEmulators) {
    //     connectFunctionsEmulator(functions, 'localhost', 5002);
    //   }
    //   return functions;
    // }),

    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
  ],
  providers: [
    provideClientHydration(),
    // Location,
    // { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer },
    // ScreenTrackingService,
    // UserTrackingService,
    // {
    //   provide: USE_AUTH_EMULATOR,
    //   useValue: environment.useEmulators
    //     ? ['http://localhost:9092']
    //     : undefined,
    // },
    // {
    //   provide: USE_DATABASE_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 9082] : undefined,
    // },
    // {
    //   provide: USE_FIRESTORE_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 8082] : undefined,
    // },
    // {
    //   provide: USE_FUNCTIONS_EMULATOR,
    //   useValue: environment.useEmulators ? ['localhost', 5002] : undefined,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
