import { Routes } from '@angular/router';

export const routes: Routes = [
  // vorschau
  {
    path: 'vorschau',
    loadChildren: () =>
      import('./pages/vorschau/vorschau-page.module').then(
        (m) => m.VorschauPageModule,
      ),
  },

  // portale/google-bewertungen-loeschen-lassen
  {
    path: 'portale/google-bewertungen-loeschen-lassen',
    loadChildren: () =>
      import('./pages/page-google/page-google.module').then(
        (m) => m.PageGoogleModule,
      ),
  },

  // portale/jameda-bewertungen-loeschen-lassen
  {
    path: 'portale/jameda-bewertungen-loeschen-lassen',
    loadChildren: () =>
      import('./pages/page-jameda/page-jameda.module').then(
        (m) => m.PageJamedaModule,
      ),
  },

  // kununu-bewertungen-loeschen-lassen
  {
    path: 'kununu-bewertungen-loeschen-lassen',
    loadChildren: () =>
      import('./pages/page-kununu/page-kununu.module').then(
        (m) => m.PageKununuModule,
      ),
  },

  // holidaycheck-bewertungen-loeschen-lassen
  // {
  //   path: 'holidaycheck-bewertungen-loeschen-lassen',
  //   loadChildren: () =>
  //     import('./pages/page-holidaycheck/page-holidaycheck.module').then(
  //       (m) => m.PageHolidaycheckModule,
  //     ),
  //   data: {
  //     id: 'holidaycheck',
  //     slug: 'holidaycheck-bewertungen-loeschen-lassen',
  //     canonicalUrl: '',
  //     dataUrl: '',
  //   },
  // },

  // abtretungserklaerung-online
  {
    path: 'abtretungserklaerung-online',
    loadChildren: () =>
      import(
        './pages/miscellaneous/assignment-declaration/assignment-declaration.module'
      ).then((m) => m.AssignmentDeclarationModule),
    data: {
      id: 'assignmentDeclaration',
      slug: 'abtretungserklaerung-online',
      canonicalUrl: '',
      dataUrl: '',
    },
  },

  // google-sterne-rechner
  {
    path: 'google-sterne-rechner',
    loadChildren: () =>
      import(
        './pages/review-calculator-page/review-calculator-page.module'
      ).then((m) => m.ReviewCalculatorPageModule),
    data: {
      id: 'reviewCalculator',
      slug: 'google-sterne-rechner',
      canonicalUrl: '',
      dataUrl: '',
    },
  },

  // checkliste
  {
    path: 'checkliste',
    loadChildren: () =>
      import('./pages/advices-page/advices-page.module').then(
        (m) => m.AdvicesPageModule,
      ),
    data: { id: 'advices', slug: 'checkliste', canonicalUrl: '', dataUrl: '' },
  },

  // loeschagentur-bewertung-loeschen-lassen
  {
    path: 'loeschagentur-bewertung-loeschen-lassen',
    loadChildren: () =>
      import(
        './pages/vorsicht-vor-illegalen-loeschagenturen-page/vorsicht-vor-illegalen-loeschagenturen-page.module'
      ).then((m) => m.VorsichtVorIllegalenLoeschagenturenPageModule),
  },

  // google-rezension-loeschen-lassen
  {
    path: 'google-rezension-loeschen-lassen',
    loadChildren: () =>
      import(
        './pages/articles/google-rezension-loeschen-lassen/google-rezension-loeschen-lassen-page.module'
      ).then((m) => m.GoogleRezensionLoeschenLassenPageModule),
  },

  // datenschutzerklaerung
  {
    path: 'datenschutzerklaerung',
    loadChildren: () =>
      import('./pages/policies/privacy-page/privacy-page.module').then(
        (m) => m.PrivacyPageModule,
      ),
    data: {
      id: 'privacy',
      slug: 'datenschutzerklaerung',
      canonicalUrl: '',
      dataUrl: '',
    },
  },

  // auftrag
  {
    path: 'auftrag',
    outlet: 'auftragOutlet',
    loadChildren: () =>
      import('./modules/place-order-dialog/place-order-dialog.module').then(
        (m) => m.PlaceOrderDialogModule,
      ),
  },

  // anfrage
  {
    path: 'anfrage',
    outlet: 'anfrageOutlet',
    loadChildren: () =>
      import(
        './modules/card-carousel-order-dialog/card-carousel-order-dialog.module'
      ).then((m) => m.CardCarouselOrderDialogModule),
  },

  // preise
  {
    path: 'preise',
    loadChildren: () =>
      import('./pages/prices-page/prices-page.module').then(
        (m) => m.PricesPageModule,
      ),
  },

  // home
  {
    path: '',
    loadChildren: () =>
      import('./pages/home-page/home-page.module').then(
        (m) => m.HomePageModule,
      ),
    data: { animation: 'HomePage' },
  },

  // contact
  {
    path: 'contact',
    // outlet: 'contactOptionsPageOutlet',
    loadChildren: () =>
      import('./pages/contact-options-page/contact-options-page.module').then(
        (m) => m.ContactOptionsPageModule,
      ),
    data: { animation: 'ContactOptionsPage' },
  },

  { path: '**', redirectTo: '' },
];
