/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import 'zone.js/dist/zone';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { Location } from '@angular/common';

// const __stripTrailingSlash = (Location as any).stripTrailingSlash;
// (Location as any).stripTrailingSlash = function _stripTrailingSlash(
//   url: string,
// ): string {
//   const queryString$ = url.match(/([^?]*)?(.*)/);
//   if (queryString$[2].length > 0) {
//     return /[^\/]\/$/.test(queryString$[1])
//       ? queryString$[1] + '.' + queryString$[2]
//       : __stripTrailingSlash(url);
//   }
//   return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
// };

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error(err));

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {
      // ngZone: 'noop',
    })
    // .then((ref) => {
    //   // Ensure Angular destroys itself on hot reloads.
    //   if (window['ngRef']) {
    //     window['ngRef'].destroy();
    //   }
    //   window['ngRef'] = ref;

    //   // Otherwise, log the boot error
    // })
    .catch((err) => console.error(err));
}

bootstrap();

// if (document.readyState === 'complete') {
//   bootstrap();
// } else {
//   document.addEventListener('DOMContentLoaded', bootstrap);
// }
