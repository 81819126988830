import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  effect,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { Router, RouterLink, RouterModule } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subject,
  fromEvent,
  of,
  takeUntil,
} from 'rxjs';
import { PageService } from '../../services/page.service';
import { MediaService } from '../../shared/media.service';
import { NavigationItem } from './models/navigation-item';

@Component({
  standalone: true,
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  // styleUrls: ['./bar.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    RouterLink,
    MatMenuModule,
    MatTreeModule,
    MatButtonModule,
    NgOptimizedImage,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') public class: string;

  @ViewChild('navList') navList: ElementRef;
  @ViewChild('dropdown1') dropdown1: MatMenuTrigger;
  @ViewChild('dropdown2') dropdown2: MatMenuTrigger;
  @ViewChild('mainNavigation', { read: ViewContainerRef })
  public mainNavigation: ViewContainerRef;

  public data$ = new BehaviorSubject<NavigationItem[]>([]);
  public destroy$ = new Subject<boolean>();

  // opened = signal(false);

  mobileNavigationActive = this.pageService.mobileNavigationActive;

  // Desktop Navigation
  private overlayContainer: HTMLElement;

  // Mobile Navigation
  public nestedTreeControl: NestedTreeControl<NavigationItem>;
  public nestedDataSource: MatTreeNestedDataSource<NavigationItem>;

  private busy = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public mediaService: MediaService,
    private pageService: PageService,
    private router: Router,
  ) {
    effect(() => {
      // console.log(`The count is: ${this.mobileNavigationActive()})`);
    });
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.mediaService.className
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          this.class = value;

          if (
            this.class &&
            (this.class.includes('handset') ||
              this.class.includes('tablet') ||
              this.class.includes('web-portrait'))
          ) {
            this.nestedTreeControl = new NestedTreeControl<NavigationItem>(
              this.getChildren,
            );
            this.nestedDataSource = new MatTreeNestedDataSource();
            this.data$.subscribe((data) => (this.nestedDataSource.data = data));
            this.data$.next([
              {
                name: 'Home',
                url: '/',
                icon: '',
                children: [],
                routerLink: true,
              },
              {
                name: 'Für Ärzte',
                url: 'https://dein-ruf.de/arzt-bewertungen/',
                icon: '',
                children: [],
              },
              {
                name: 'Team',
                url: 'https://dein-ruf.de/unser-team/',
                icon: '',
                children: [],
              },
              {
                name: 'Videos',
                url: 'https://dein-ruf.de/google-rezensionen-loeschen-videos/',
                icon: '',
                children: [],
              },
              {
                name: 'Referenzen',
                url: 'https://dein-ruf.de/referenzen/',
                icon: '',
                children: [],
              },
              {
                name: 'Preise',
                url: 'preise',
                icon: '',
                children: [],
                routerLink: true,
              },
              {
                name: 'Checkliste - Sofortmaßnahmen',
                url: 'checkliste',
                icon: '',
                children: [],
                routerLink: true,
              },
              {
                name: 'Ratgeber',
                url: '',
                icon: '',
                children: [
                  {
                    name: 'Bewertung online prüfen',
                    url: 'https://dein-ruf.de/bewertung-online-pruefen/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Tipps & Tricks',
                    url: 'https://dein-ruf.de/category/ratgeber/tipps-tricks/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'FAQ',
                    url: 'https://dein-ruf.de/faq/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Galileo Pro7 Interview',
                    url: 'https://dein-ruf.de/ratgeber/galilieo-interview/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Nachteile ohne Anwalt',
                    url: 'https://dein-ruf.de/nachteile-ohne-anwalt/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Warum zum Anwalt',
                    url: 'https://dein-ruf.de/warum-zum-anwalt/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Hinweise',
                    url: 'https://dein-ruf.de/category/ratgeber/hinweise/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Portale',
                    url: 'https://dein-ruf.de/category/ratgeber/portale-ratgeber/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Allgemein',
                    url: 'https://dein-ruf.de/category/ratgeber/allgemein-ratgeber/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Google Rezensionen',
                    url: 'google-rezension-loeschen-lassen',
                    icon: '',
                    children: [],
                    routerLink: true,
                  },
                  {
                    name: 'Illegale Löschagenturen',
                    url: 'loeschagentur-bewertung-loeschen-lassen',
                    icon: '',
                    children: [],
                    routerLink: true,
                  },
                ],
              },
              {
                name: 'Portale',
                url: '',
                icon: '',
                children: [
                  {
                    name: 'Google',
                    url: 'portale/google-bewertungen-loeschen-lassen',
                    icon: '',
                    children: [],
                    routerLink: true,
                  },
                  {
                    name: 'Facebook',
                    url: 'https://dein-ruf.de/portale/facebook-bewertungen-loeschen-lassen/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Kununu',
                    url: 'kununu-bewertungen-loeschen-lassen',
                    icon: '',
                    routerLink: true,
                    children: [],
                  },
                  {
                    name: 'Jameda',
                    url: 'portale/jameda-bewertungen-loeschen-lassen',
                    icon: '',
                    routerLink: true,
                    children: [],
                  },
                  {
                    name: 'Yelp',
                    url: 'https://dein-ruf.de/portale/yelp-bewertungen-loeschen-lassen/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Trustpilot',
                    url: 'https://dein-ruf.de/trustpilot-bewertungen-loeschen-lassen/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'Tripadvisor',
                    url: 'https://dein-ruf.de/tripadvisor-bewertungen-loeschen-lassen/',
                    icon: '',
                    children: [],
                  },
                  {
                    name: 'HolidayCheck',
                    url: 'https://dein-ruf.de/holidaycheck-bewertungen-loeschen-lassen/',
                    icon: '',
                    children: [],
                  },
                ],
              },
              {
                name: 'Kontakt',
                url: 'https://dein-ruf.de/kontakt/',
                icon: 'https://dein-ruf.de/assets/icons/mobilenav/person_black_24dp.svg',
                children: [],
              },
              {
                name: 'Online Auftrag',
                url: 'https://dein-ruf.de/online-auftrag/',
                icon: 'https://dein-ruf.de/assets/icons/mobilenav/done-black-18dp.svg',
                children: [],
              },
              {
                name: '030 29 68 11 18',
                url: 'tel:+493029681118',
                icon: 'https://dein-ruf.de/assets/icons/mobilenav/phone-black-18dp.svg',
                children: [],
              },
              {
                name: 'Bewertung online prüfen',
                url: 'https://dein-ruf.de/bewertung-online-pruefen/',
                icon: 'https://dein-ruf.de/assets/icons/mobilenav/important_devices-black-18dp.svg',
                children: [],
              },
            ]);
          }

          if (this.class && this.class.includes('web-landscape')) {
          }
        });
    });
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // Desktop Navigation

  public open(menuTrigger: MatMenuTrigger, eventName: string): void {
    this.busy = true;
    Promise.resolve()
      .then(() => {
        if (!menuTrigger.menuOpen) {
          this.dropdown1?.closeMenu();
          this.dropdown2?.closeMenu();
          menuTrigger.openMenu();
        }
      })
      .then(() => {
        this.overlayContainer = this.document.querySelector('.scrim');
        this.overlayContainer.classList.add('display-block--important');
        this.overlayContainer.classList.add('z-index-999--important');
        if (this.overlayContainer) {
          fromEvent(this.overlayContainer, 'mouseenter')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              setTimeout(() => {
                this.dropdown1?.closeMenu();
                this.dropdown2?.closeMenu();
                this.overlayContainer.classList.remove(
                  'display-block--important',
                );
                this.overlayContainer.classList.remove(
                  'z-index-999--important',
                );
              }, 350);
            });
        }
      });
  }

  public async openOnlineRequestDialog(): Promise<void> {
    const scrollTop = this.document.querySelector('html').scrollTop;
    this.pageService.setPreviousScrollPosition(scrollTop);

    this.router.navigate(['', { outlets: { auftragOutlet: 'auftrag' } }], {
      skipLocationChange: true,
    });
  }

  public openUrl(url: string): void {
    this.document.defaultView.open(url);
  }

  // Mobile Navigation

  public getChildren(node: NavigationItem): Observable<NavigationItem[]> {
    return of(node.children);
  }

  public toggleMenu(): void {
    console.log('toggleMenu');
    this.pageService.toggleMobileNavigationActive();
  }

  public hasNestedChild(_: number, nodeData: NavigationItem): number {
    return nodeData.children.length;
  }

  public close(): void {
    // this.opened.set(false);
    this.pageService.setMobileNavigationActive(false);
  }
}
