<div class="nav">

  <div class="nav-slot bg-white round-top-left">
    <!-- <button class="nav-link active" (click)="openContactOptionsPage()">
      <div class="icon"></div>
      <span>Kontakt</span>
    </button> -->
    <a [routerLink]="['contact']" [routerLinkActive]="'active'" class="nav-link">
      <div class="icon">
        <img
        width="24"
        height="24"
        ngSrc="assets/components/bottom-navigation-bar/icons/contact-icon.svg"
        alt="Kontakt Icon (Dein-Ruf.de)"
        loading="auto">
      </div>
      <span>Kontakt</span>
    </a>
  </div>

  <div class="nav-slot bg-white">
    <a [routerLink]="['google-sterne-rechner']" [routerLinkActive]="'active'" class="nav-link">
      <div class="icon">
        <img
        width="24"
        height="24"
        ngSrc="assets/components/bottom-navigation-bar/icons/chart-icon.svg"
        alt="Analyse Icon (Dein-Ruf.de)"
        loading="auto">
      </div>
      <span>Analyse</span>
    </a>
  </div>

  <div class="nav-slot curve">
    <button
    role="button"
    class="menu-toggle floating-button"
    aria-label="Menu Button"
    [class.opened]="mobileNavigationActive()"
    (click)="toggleMenu();">
      <div id="navIcon4" [class.open]="mobileNavigationActive()">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </button>
  </div>

  <div class="nav-slot bg-white">
    <button (click)="openDialog()" class="nav-link" [class.active]="placeOrderDialogActive()">
      <div class="icon">
        <img
        width="24"
        height="24"
        ngSrc="assets/components/bottom-navigation-bar/icons/assignment-icon.svg"
        alt="Auftrag Icon (Dein-Ruf.de)"
        loading="auto">
      </div>
      <span>Auftrag</span>
    </button>
  </div>

  <div class="nav-slot bg-white round-top-right">
    <a
    class="nav-link"
    href="https://api.whatsapp.com/send?phone=4916097211227&text=Hallo%20Herr%20Schulz%2C%20ich%20ben%C3%B6tige%20zus%C3%A4tzliche%20Information..."
    target="_blank">
      <div class="icon">
        <img
        width="24"
        height="24"
        ngSrc="assets/components/bottom-navigation-bar/icons/whatsapp-icon.svg"
        alt="WhatsApp Icon (Dein-Ruf.de)"
        loading="auto">
      </div>
      <span>WhatsApp</span>
    </a>
  </div>

</div>