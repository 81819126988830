import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EnvironmentInjector,
  HostBinding,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
  createComponent,
  createEnvironmentInjector,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { BehaviorSubject, Subject, filter, takeUntil } from 'rxjs';
import { slideInAnimation } from './animations';
import { ContactPopupFormComponent } from './modules/contact-popup-form/contact-popup-form.component';
import { PageService } from './services/page.service';
import { MediaService } from './shared/media.service';
import { formatDateWithZone } from './shared/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [slideInAnimation],
  // styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class') public class: string;

  @ViewChild('footerComponent', { read: ViewContainerRef })
  public footerComponent: ViewContainerRef;

  public loading$ = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<boolean>();

  // Page State
  public pageId: string;
  public pageSlug: string;
  public dataUrl = 'https://dein-ruf.de/data/pages/home-page';
  public metaTitle = 'Google Bewertungen löschen lassen | Tricks vom Anwalt';
  public metaDescription =
    'Spezialkanzlei für Reputationsschutz: Kostenlose Tools und Checklisten für Notfälle! Löschungen ab 89 € oder kostenlos mit Rechtsschutzversicherung.';

  public footerRendered = false;
  public hideFooter = false;

  private addContactDialog: Promise<Type<ContactPopupFormComponent>>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private pageService: PageService,
    private mediaService: MediaService,
    private matDialog: MatDialog,
    private injector: Injector,
    private environmentInjector: EnvironmentInjector,
    private title: Title,
    private meta: Meta,
    // private swUpdate: SwUpdate,
    // private appRef: ApplicationRef,
    private router: Router,
    private contexts: ChildrenOutletContexts,
  ) {
    // this.updateClient();
  }

  public ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.pageService.previousScrollPosition$
        .pipe(filter((x) => x !== 0))
        .subscribe((next) => {
          setTimeout(() => {
            this.document.querySelector('html').scrollTop = next;
          });
        });
    });

    setTimeout(() => {
      this.initMetaData();
      setTimeout(() => {
        const scrollbarWidth =
          this.document.defaultView.innerWidth -
          this.document.documentElement.clientWidth;
        this.pageService.setScrollbarWidth(scrollbarWidth);
        setTimeout(() => {
          this.mediaService.className
            .pipe(takeUntil(this.destroy$))
            .subscribe((next: string) => {
              this.class = next;
            });
        });
      });
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadComponents();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public initMetaData(): void {
    this.title.setTitle(this.metaTitle);

    this.meta.addTags([
      {
        name: 'description',
        content: this.metaDescription,
      },
      { name: 'theme-color', content: '#00204d' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      {
        name: 'robots',
        content:
          'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large',
      },
      { name: 'og:type', content: 'website' },
      { name: 'og:locale', content: 'de_DE' },
      { name: 'og:site_name', content: 'Dein-Ruf.de' },
      {
        name: 'og:title',
        content: this.metaTitle,
      },
      {
        name: 'og:description',
        content: this.metaDescription,
      },
      { name: 'og:url', content: 'https://dein-ruf.de/' },
      {
        name: 'og:updated_time',
        content: formatDateWithZone(),
      },
      { name: 'twitter:card', content: 'summary_large_image' },
      {
        name: 'twitter:title',
        content: this.metaTitle,
      },
      {
        name: 'twitter:description',
        content: this.metaDescription,
      },
      { name: 'twitter:site', content: '@deinrufberlin' },
      { name: 'twitter:creator', content: '@deinrufberlin' },
    ]);
  }

  public loadComponents(): void {
    // Footer Component
    // import('./components/footer/lazy-footer').then((lazyImport) => {
    //   const childEnvironmentInjector = createEnvironmentInjector(
    //     lazyImport.providers,
    //     this.environmentInjector,
    //     'App Component: Lazy Environment Injector',
    //   );
    //   const lazyFooterComponent = createComponent(lazyImport.FooterComponent, {
    //     environmentInjector: childEnvironmentInjector,
    //     elementInjector: this.injector,
    //   });
    //   this.footerComponent.insert(lazyFooterComponent.hostView);
    // });
  }

  public openUrl(url: string): void {
    this.document.defaultView.open(url);
  }

  public call(url: string): void {
    this.document.defaultView.open(url);
  }

  public openContactPopupForm(): void {
    // Lazy load the component
    if (!this.addContactDialog) {
      this.addContactDialog = import(
        './modules/contact-popup-form/contact-popup-form.component'
      ).then(({ ContactPopupFormComponent }) => ContactPopupFormComponent);
    }

    this.addContactDialog.then((component: Type<ContactPopupFormComponent>) => {
      this.matDialog.open(component, {
        width: '480px',
      });
    });
  }

  // public updateClient(): void {
  //   if (environment.production) {
  //     if (this.document && this.document.defaultView) {
  //       if (this.document.defaultView.caches) {
  //         this.document.defaultView.caches.keys().then((names) => {
  //           for (const name of names) {
  //             console.log(name);

  //             this.document.defaultView.caches.delete(name);
  //           }
  //         });
  //       }

  //       if (this.document.defaultView.navigator) {
  //         // Allow the app to stabilize first, before starting
  //         // polling for updates with `interval()`.
  //         const appIsStable$ = this.appRef.isStable.pipe(
  //           first((isStable) => isStable === true),
  //         );
  //         const everySixHours$ = interval(6 * 60 * 60 * 1000);
  //         const everySixHoursOnceAppIsStable$ = concat(
  //           appIsStable$,
  //           everySixHours$,
  //         );

  //         everySixHoursOnceAppIsStable$.subscribe(async () => {
  //           try {
  //             const updateFound = await this.swUpdate.checkForUpdate();
  //             console.log(
  //               updateFound
  //                 ? 'A new version is available.'
  //                 : 'Already on the latest version.',
  //             );
  //             if (updateFound) {
  //               this.swUpdate.versionUpdates
  //                 .pipe(
  //                   filter(
  //                     (evt): evt is VersionReadyEvent =>
  //                       evt.type === 'VERSION_READY',
  //                   ),
  //                 )
  //                 .subscribe(() => {
  //                   document.location.reload();

  //                   // this.swUpdate.activateUpdate().then(() => {
  //                   //   this.document.location.reload();
  //                   // });
  //                   // if (promptUser(evt)) {
  //                   //   // Reload the page to update to the latest version.
  //                   //   document.location.reload();
  //                   // }
  //                 });
  //             }
  //           } catch (err) {
  //             console.error('Failed to check for updates:', err);
  //           }
  //         });

  //         //   if ('serviceWorker' in navigator && environment.production) {
  //         //     this.swUpdate.checkForUpdate().then((value: boolean) => {
  //         //       if (value) {
  //         //         this.swUpdate.activateUpdate().then(() => {
  //         //           this.document.location.reload();
  //         //         });
  //         //       }
  //         //     });
  //         //     this.swUpdate.versionUpdates.subscribe((next: VersionEvent) => {
  //         //       if (next.type === 'VERSION_READY') {
  //         //         this.document.location.reload();
  //         //       }
  //         //     });
  //         //   }
  //         // }
  //       }
  //     }
  //   }
  // }

  public footerIntersection(event: IntersectionObserverEntry[]): void {
    event.forEach((entry) => {
      if (entry.isIntersecting) {
        // console.log('footerIntersection');
        import('./components/footer/lazy-footer').then((lazyImport) => {
          const childEnvironmentInjector = createEnvironmentInjector(
            lazyImport.providers,
            this.environmentInjector,
            'App Component > Footer Component: Lazy Environment Injector',
          );
          const lazyComponent = createComponent(lazyImport.FooterComponent, {
            environmentInjector: childEnvironmentInjector,
            elementInjector: this.injector,
          });
          if (this.footerComponent && !this.footerRendered) {
            this.footerRendered = true;
            this.footerComponent.insert(lazyComponent.hostView);
          }
        });
      }
    });
  }

  public getRouteAnimationData() {
    if (
      this.contexts.getContext('primary')?.injector?.['source'] ===
      'ContactOptionsPageModule'
    ) {
      this.hideFooter = true;
    } else {
      this.hideFooter = false;
    }
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }
}
