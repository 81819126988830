import { HostListener, Inject, Injectable } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  className: Observable<string>;

  private current = new Subject<string>();
  current$ = this.current.asObservable();

  orientation = 0;

  state: string = null;

  @HostListener('window:resize') updateOrientatioState() {
    if (window.innerHeight > window.innerWidth) {
      // console.log('portrait');
    } else {
      // console.log('landscape');
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public breakpointObserver: BreakpointObserver,
  ) {
    // console.log(Breakpoints.Handset);
    // console.log(Breakpoints.HandsetPortrait);
    // console.log(Breakpoints.HandsetLandscape);
    // console.log(Breakpoints.Tablet);
    // console.log(Breakpoints.TabletPortrait);
    // console.log(Breakpoints.TabletLandscape);
    // console.log(Breakpoints.Web);
    // console.log(Breakpoints.WebPortrait);
    // console.log(Breakpoints.WebLandscape);

    this.className = new Observable<string>((observer) => {
      // observer.next('web web-landscape');

      this.breakpointObserver
        .observe([
          // '(orientation: portrait)',
          // '(orientation: landscape)',
          Breakpoints.Handset,
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.Tablet,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
          Breakpoints.WebPortrait,
          Breakpoints.WebLandscape,
        ])
        .subscribe((state: BreakpointState) => {
          // console.log(Breakpoints.Handset);
          // console.log(Breakpoints.HandsetPortrait);
          // console.log(Breakpoints.HandsetLandscape);
          // console.log(Breakpoints.Tablet);
          // console.log(Breakpoints.TabletPortrait);
          // console.log(Breakpoints.TabletLandscape);
          // console.log(Breakpoints.Web);
          // console.log(Breakpoints.WebPortrait);
          // console.log(Breakpoints.WebLandscape);

          // console.log(state);

          if (this.document.defaultView) {
            if (this.document.defaultView?.screen) {
              if (this.document.defaultView.screen.orientation) {
                if (this.document.defaultView.screen.orientation.type) {
                  if (
                    this.document.defaultView.screen.orientation.type ===
                    'portrait-primary'
                  ) {
                    this.orientation = 0;
                  }
                  if (
                    this.document.defaultView.screen.orientation.type ===
                    'landscape-primary'
                  ) {
                    this.orientation = 1;
                  }
                }
              }

              // console.log(state);
              // console.log(this.orientation);

              if (state.breakpoints[Breakpoints.Handset]) {
                observer.next('handset');
                // this.setHP();
              }

              if (state.breakpoints[Breakpoints.HandsetPortrait]) {
                observer.next('handset handset-portrait');
                // this.setHP();
              }

              if (state.breakpoints[Breakpoints.HandsetLandscape]) {
                // observer.next('hl');
                // this.setHL();
                observer.next('handset handset-landscape');
                // this.setHL();
              }

              if (state.breakpoints[Breakpoints.Tablet]) {
                observer.next('tablet');
                // this.setTP();
              }

              if (state.breakpoints[Breakpoints.TabletPortrait]) {
                observer.next('tablet tablet-portrait');
                // this.setTP();
              }

              if (state.breakpoints[Breakpoints.TabletLandscape]) {
                observer.next('tablet tablet-landscape');
                // this.setTL();
              }

              if (state.breakpoints[Breakpoints.Web]) {
                observer.next('web');
                // this.setWP();
              }

              if (state.breakpoints[Breakpoints.WebPortrait]) {
                observer.next('web web-portrait');
                // this.setWP();
              }

              if (state.breakpoints[Breakpoints.WebLandscape]) {
                observer.next('web web-landscape');
                // this.setWL();
              }

              // if (state.breakpoints[Breakpoints.Handset]) {
              //   if (this.orientation === 0) {
              //     observer.next('hp');
              //     this.setHP();
              //     this.handsetPortrait.next(true);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              //   if (this.orientation === 1) {
              //     observer.next('hl');
              //     this.setHL();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(true);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              // }

              // if (state.breakpoints[Breakpoints.Tablet]) {
              //   if (this.orientation === 0) {
              //     observer.next('tp');
              //     this.setTP();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(true);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              //   if (this.orientation === 1) {
              //     observer.next('tl');
              //     this.setTL();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(true);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              // }

              // if (state.breakpoints[Breakpoints.Web]) {
              //   if (this.orientation === 0) {
              //     observer.next('wp');
              //     this.setWP();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(true);
              //     this.webLandscape.next(false);
              //   }
              //   if (this.orientation === 1) {
              //     observer.next('wl');
              //     this.setWL();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(true);
              //   }
              // }

              // if (state.breakpoints[Breakpoints.HandsetPortrait]) {
              //   if (this.orientation === 0) {
              //     observer.next('hp');
              //     this.setHP();
              //     this.handsetPortrait.next(true);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              //   if (this.orientation === 1) {
              //     observer.next('hl');
              //     this.setHL();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(true);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              // }
              // if (state.breakpoints[Breakpoints.HandsetLandscape]) {
              //   if (this.orientation === 0) {
              //     observer.next('hp');
              //     this.setHP();
              //     this.handsetPortrait.next(true);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              //   if (this.orientation === 1) {
              //     observer.next('hl');
              //     this.setHL();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(true);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              // }
              // if (state.breakpoints[Breakpoints.TabletPortrait]) {
              //   if (this.orientation === 0) {
              //     observer.next('tp');
              //     this.setTP();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(true);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              //   if (this.orientation === 1) {
              //     observer.next('tl');
              //     this.setTL();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(true);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              // }
              // if (state.breakpoints[Breakpoints.TabletLandscape]) {
              //   if (this.orientation === 0) {
              //     observer.next('tp');
              //     this.setTP();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(true);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              //   if (this.orientation === 1) {
              //     observer.next('tl');
              //     this.setTL();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(true);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(false);
              //   }
              // }
              // if (state.breakpoints[Breakpoints.WebPortrait]) {
              //   if (this.orientation === 0) {
              //     observer.next('wp');
              //     this.setWP();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(true);
              //     this.webLandscape.next(false);
              //   }
              //   if (this.orientation === 1) {
              //     observer.next('wl');
              //     this.setWL();
              //     this.handsetPortrait.next(false);
              //     this.handsetLandscape.next(false);
              //     this.tabletPortrait.next(false);
              //     this.tabletLandscape.next(false);
              //     this.webPortrait.next(false);
              //     this.webLandscape.next(true);
              //   }
              // }
              // if (state.breakpoints[Breakpoints.WebLandscape]) {
              //   observer.next('wl');
              //   this.setWL();
              //   this.handsetPortrait.next(false);
              //   this.handsetLandscape.next(false);
              //   this.tabletPortrait.next(false);
              //   this.tabletLandscape.next(false);
              //   this.webPortrait.next(false);
              //   this.webLandscape.next(true);
              // }
            }
          }
        });
    });

    // Promise.resolve()
    //   .then(() => {})
    //   .then(() => {});
  }
}
