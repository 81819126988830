<div style="display: block;" class="logo">
  <a style="display: block;" routerLink="/">
    <img
    src="assets/logos/logo-web_dein-ruf.de.svg"
    alt="Logo: Dein-Ruf.de (Google Rezensionen löschen)"
    width="173"
    height="51">
  </a>
</div>
<nav class="navigation-desktop">
  <ul #navList>
    <li>
      <a href="https://dein-ruf.de/arzt-bewertungen/" mat-button>Für Ärzte</a>
    </li>
    <li>
      <a href="https://dein-ruf.de/unser-team/" mat-button>Team</a>
    </li>
    <li>
      <a href="https://dein-ruf.de/google-rezensionen-loeschen-videos/" mat-button>Videos</a>
    </li>
    <li>
      <a mat-button href="https://dein-ruf.de/referenzen/">Referenzen</a>
    </li>
    <li>
      <a mat-button routerLink="/preise">Preise</a>
    </li>
    <li class="dropdown">
      <button mat-button [matMenuTriggerFor]="ratgeber" #dropdown1="matMenuTrigger" (mouseenter)="open(dropdown1, 'mouseenter')" (click)="open(dropdown1, 'click')" overlapTrigger="false">
        Ratgeber
      </button>
      <mat-menu #ratgeber="matMenu" xPosition="after" [hasBackdrop]="false">
        <span>
          <a mat-menu-item routerLink="/checkliste">Checkliste - Sofortmaßnahmen</a>
          <a mat-menu-item routerLink="/google-rezension-loeschen-lassen">Google Rezensionen</a>
          <a mat-menu-item routerLink="/loeschagentur-bewertung-loeschen-lassen">Illegale Löschagenturen</a>
          <a mat-menu-item href="https://dein-ruf.de/bewertung-online-pruefen/">Bewertung online prüfen</a>
          <a mat-menu-item href="https://dein-ruf.de/category/ratgeber/tipps-tricks/">Tipps &amp; Tricks</a>
          <a mat-menu-item href="https://dein-ruf.de/faq/">FAQ</a>
          <a mat-menu-item href="https://dein-ruf.de/ratgeber/galilieo-interview/">Galileo Pro7 Interview</a>
          <a mat-menu-item href="https://dein-ruf.de/nachteile-ohne-anwalt/">Nachteile ohne Anwalt</a>
          <a mat-menu-item href="https://dein-ruf.de/warum-zum-anwalt/">Warum zum Anwalt</a>
          <a mat-menu-item href="https://dein-ruf.de/category/ratgeber/hinweise/">Hinweise</a>
          <a mat-menu-item href="https://dein-ruf.de/category/ratgeber/portale-ratgeber/">Portale</a>
          <a mat-menu-item href="https://dein-ruf.de/category/ratgeber/allgemein-ratgeber/">Allgemein</a>
        </span>
      </mat-menu>
    </li>
    <li class="dropdown">
      <button mat-button [matMenuTriggerFor]="portale" #dropdown2="matMenuTrigger" (mouseenter)="open(dropdown2, 'mouseenter')" (click)="open(dropdown2, 'click')" overlapTrigger="false">
        Portale
      </button>
      <mat-menu #portale="matMenu" xPosition="after" [hasBackdrop]="false">
        <span>
          <a mat-menu-item routerLink="/portale/google-bewertungen-loeschen-lassen">
            Google
          </a>
          <a mat-menu-item href="https://dein-ruf.de/portale/facebook-bewertungen-loeschen-lassen/">Facebook</a>
          <a mat-menu-item routerLink="/kununu-bewertungen-loeschen-lassen">
            Kununu
          </a>
          <a mat-menu-item routerLink="/portale/jameda-bewertungen-loeschen-lassen">
            Jameda
          </a>
          <a mat-menu-item href="https://dein-ruf.de/portale/yelp-bewertungen-loeschen-lassen/">Yelp</a>
          <a mat-menu-item href="https://dein-ruf.de/trustpilot-bewertungen-loeschen-lassen/">Trustpilot</a>
          <a mat-menu-item href="https://dein-ruf.de/tripadvisor-bewertungen-loeschen-lassen/">Tripadvisor</a>
          <a mat-menu-item routerLink="/holidaycheck-bewertungen-loeschen-lassen">
            Holidaycheck
          </a>
        </span>
      </mat-menu>
    </li>
  </ul>
  <ng-template #mainNavigation></ng-template>
</nav>

<nav *ngIf="mobileNavigationActive() && nestedDataSource" class="navigation-mobile">

<ng-container *ngIf="(data$ | async) && nestedTreeControl">
<mat-tree
class="example-tree"
[dataSource]="nestedDataSource"
[treeControl]="nestedTreeControl">
<mat-tree-node *matTreeNodeDef="let node">
<li class="mat-tree-node">
<div
*ngIf="node.icon"
class="mobile-nav-icon">
<img [src]="node.icon">
</div>
<ng-container *ngIf="node.routerLink">
<a [routerLink]="['' + node.url + '']" (click)="close()">
{{node.name}}
</a>
</ng-container>
<ng-container *ngIf="!node.routerLink">
<a [href]="node.url">
{{node.name}}
</a>
</ng-container>
</li>
</mat-tree-node>
<mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
<li class="mat-tree-node">
<ng-container
*ngIf="node.routerLink">
<a [routerLink]="['' + node.url + '']" (click)="close()">
{{node.name}}
</a>
</ng-container>
<ng-container
*ngIf="!node.routerLink">
<a matTreeNodeToggle>
{{node.name}}
</a>
</ng-container>
<button mat-icon-button
matTreeNodeToggle>
<div class="expand-icon">
<img
[src]="nestedTreeControl.isExpanded(node) ? 'assets/icons/expand_less-black-18dp.svg' : 'assets/icons/expand_more-black-18dp.svg'">
</div>
</button>
{{node.filename}}
<ul [class.invisible]="!nestedTreeControl.isExpanded(node)">
<ng-container matTreeNodeOutlet>
</ng-container>
</ul>
</li>
</mat-nested-tree-node>
</mat-tree>
</ng-container>

</nav>

<button
mat-icon-button
class="menu-toggle"
aria-label="Menu Button"
color="accent"
[class.opened]="mobileNavigationActive()"
(click)="toggleMenu();">
  <div id="navIcon4" [class.open]="mobileNavigationActive()">
    <span></span>
    <span></span>
    <span></span>
  </div>
</button>

<button
mat-raised-button
id="headerButton1"
class="navigation__header-button"
color="primary"
(click)="openOnlineRequestDialog()">
  <div class="button-content">
    <img
    ngSrc="assets/icons/assignment-white_FILL1_wght400_GRAD0_opsz20.svg"
    width="20"
    height="20"
    alt="Google Bewertungen löschen: Icon (Online Auftrag)"
    loading="eager">
    <span class="button-text" style="color: #000 !important;">
      Online Auftrag
    </span>
  </div>
</button>
<button
mat-raised-button
id="headerButton2"
class="navigation__header-button"
color="accent"
(click)="openUrl('tel:+493029681118')">
  <div class="button-content">
    <img
    ngSrc="assets/icons/call-white_FILL1_wght400_GRAD0_opsz20.svg"
    width="20"
    height="20"
    alt="Google Bewertungen löschen: Icon (Telefon)"
    loading="eager">
    <span class="button-text">
      030 29 68 11 18
    </span>
  </div>
</button>